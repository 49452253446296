<template>
  <div
    v-show="
      (apolice && apolice.PolicyUniqueId) || $route.name == 'endosso-emissao'
    "
  >
    <breadcrumb
      :title="`Endosso de ${tipo}`"
      :actualPage="apolice.PolicyNumber || (proposta || {}).Number"
      class="mb-6 w-full"
    />

    <vs-card class="p-8">
      <ValidationObserver v-slot="{ invalid }" id="endosso-wizard">
        <form-wizard
          color="rgba(var(--vs-primary), 1)"
          :title="null"
          :subtitle="null"
          :startIndex="startWizard"
          finishButtonText="Submit"
          class="proposta"
          v-if="
            startWizard == 2
              ? (proposta || {}).UniqueId == $route.params.uniqueId
              : true
          "
        >
          <template slot="step" slot-scope="props">
            <wizard-step
              :tab="props.tab"
              :transition="props.transition"
              :key="props.tab.title"
              :disabled="{}.StatusId === 3"
              :index="props.index"
              :showNumber="true"
            >
            </wizard-step>
          </template>

          <tab-content title="Informações da apólice/endosso" class="mb-8 mt-8">
            <router-view v-if="$route.name != 'endosso-emissao'"></router-view>
          </tab-content>

          <tab-content title="Motivos e documentos" class="mb-8 mt-8">
            <dados-motivo-endosso
              v-if="
                listMotivos &&
                  listMotivos.length &&
                  apolice &&
                  currentPage == 1 &&
                  $route.name != 'endosso-emissao'
              "
              :listMotivos="listMotivos"
              :listObject="listObject"
              :listObjectArray="listObjectArray"
              @dispatchList="listObjectDispatch"
              :endorsementChangeTypeIds="endorsementChangeTypeIds"
              :apolice="apolice"
            />
          </tab-content>

          <tab-content title="Dados financeiros" class="mb-8 mt-8">
            <router-view v-if="$route.name == 'endosso-emissao'"></router-view>
          </tab-content>

          <template slot="footer" slot-scope="props">
            <div>
              <div class="wizard-footer-left">
                <vs-button
                  v-if="props.activeTabIndex > 0 && props.activeTabIndex <= 1"
                  @click.native="
                    props.prevTab();
                    currentPage--;
                  "
                  color="primary"
                  type="border"
                >
                  <span class="font-semibold pr-2 pl-2">Voltar</span>
                </vs-button>
              </div>

              <div class="wizard-footer-right">
                <template v-if="props.activeTabIndex <= 1">
                  <vs-button
                    @click.native="resetarEndosso()"
                    color="grey"
                    class="mr-5"
                  >
                    <span class="font-semibold pr-2 pl-2"> Cancelar </span>
                  </vs-button>
                  <vs-button
                    @click.native="
                      props.activeTabIndex == 0
                        ? loadPostValidateEndorsement(props)
                        : enviarEndossoAnalise()
                    "
                    :disabled="
                      disableProsseguirButton(props.activeTabIndex) || invalid
                    "
                    color="primary"
                  >
                    <span class="font-semibold pr-2 pl-2">
                      {{
                        props.activeTabIndex == 0
                          ? "Prosseguir"
                          : "Enviar para a seguradora"
                      }}
                    </span>
                  </vs-button>
                </template>
              </div>
            </div>
          </template>
        </form-wizard>
      </ValidationObserver>
    </vs-card>

    <vs-popup
      title=""
      :active.sync="regrasAprovacaoAutotaticasModal"
      :button-close-hidden="regraPodeFecharModal"
      id="loading-step-endosso"
    >
      <div class="px-6 pb-6">
        <h3 class="text-primary">Processando a solicitação de endosso</h3>
        <h6 class="mb-10">Aguarde, estamos processando sua solicitação.</h6>

        <div v-for="(step, index) in getSteps" :key="index">
          <p :class="['flex', 'font-semibold']">
            <span
              :class="[`loading-step-${step.status}`]"
              class="mr-2 loading-step-block vs-con-loading__container"
              :id="[`loading-step-${step.name}`]"
            >
            </span>
            <span
              v-if="step.status == 'error'"
              class="mr-2 material-icons text-danger"
            >
              cancel
            </span>
            <span
              v-if="step.status == 'success'"
              class="mr-2 material-icons text-success"
            >
              check_circle
            </span>
            <span
              v-if="step.status == 'warning'"
              class="mr-2 material-icons text-warning"
            >
              warning
            </span>
            <span class="pt-2 pl-5" v-if="step.status != 'wait'">{{
              step.message
            }}</span>
          </p>
          <vs-alert
            color="warning"
            title=""
            :active="step.status == 'warning'"
            class="mt-4"
          >
            <p
              class="text-warning flex font-semibold mt-2"
              v-for="(message, index) in step.alerts"
              :key="index"
            >
              <span class="mr-2 material-icons"> warning </span>
              <span class="mt-1">{{ message }}</span>
            </p>
          </vs-alert>

          <vs-alert
            color="danger"
            title=""
            :active="step.status == 'error'"
            class="mt-4"
          >
            <p
              class="text-danger flex font-semibold mt-2"
              v-for="(message, index) in step.error"
              :key="index"
            >
              <span class="mr-2 material-icons"> cancel </span>
              <span class="mt-1">{{ message }}</span>
            </p>
          </vs-alert>
        </div>

        <div
          class="text-right"
          v-if="apolice.PolicyUniqueId != undefined && finishRules"
        >
          <vs-button color="grey" class="mt-8 mr-3" @click="goToRoute()"
            >Cancelar</vs-button
          >
          <vs-button
            v-if="showButtonProsseguir"
            :disabled="!habilitaButtonProsseguir && !finishRules"
            color="primary"
            class="mt-8 mr-3"
            type="filled"
            @click="goToEmissao()"
            >Prosseguir</vs-button
          >

          <vs-button
            v-else
            :disabled="!habilitaButtonProsseguir && !finishRules"
            color="primary"
            class="mt-8 mr-3"
            type="filled"
            @click="goToRoute('time-line')"
            >Seguir para timeline</vs-button
          >
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import DadosMotivoEndosso from "./components/DadosMotivoEndosso";
import Breadcrumb from "@/layouts/components/breadcrumb/Breadcrumb";
import instance from "@/axios";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "solicitacao-endosso",
  components: {
    Breadcrumb,
    DadosMotivoEndosso
  },
  data() {
    return {
      currentPage: 0,
      showButtonProsseguir: true,
      regrasAprovacaoAutotaticasModal: false,
      regraPodeFecharModal: true,
      habilitaButtonProsseguir: false,
      finishRules: false,
      changed: false,
      listMotivos: [],
      listObject: [],
      listObjectArray: [],
      endorsementChangeTypeIds: [],
      enviarParaSeguradoraSteps: []
    };
  },
  watch: {
    endosso(value) {
      if (this.apolice && this.apolice.PolicyUniqueId)
        this.validChangedObject({
          value: value,
          valueCompare: this.apolice
        }).then(result => {
          this.changed = result;
        });
    }
  },
  computed: {
    ...mapGetters("proposta-module", ["proposta"]),
    ...mapGetters("apolice-module", ["apolice"]),
    ...mapGetters("endosso-module", [
      "endosso",
      "endossoPayload",
      "endossoCreatePayload"
    ]),

    getSteps() {
      return this.enviarParaSeguradoraSteps;
    },

    tipo: {
      get() {
        return this.$route.name == "endosso-cancelamento" ||
          ((this.proposta || {}).Endorsement || {}).EndorsementTypeId == 1
          ? "Cancelamento"
          : "Alteração";
      }
    },

    startWizard() {
      return this.$route.name == "endosso-emissao" ? 2 : 0;
    }
  },

  methods: {
    ...mapMutations("endosso-module", [
      "updateEndosso",
      "updateSeguradoraTags"
    ]),
    ...mapMutations("apolice-module", ["updateApolice"]),
    ...mapActions("proposta-module", [
      "loadPropostaByUniqueId",
      "getCompanyProposalTags"
    ]),
    ...mapActions("apolice-module", [
      "loadApoliceByUniqueId",
      "loadEndossoRules",
      "resetApoliceModule"
    ]),
    ...mapActions("endosso-module", [
      "createEndorsementPolicy",
      "endossoSubscricao",
      "endossoResseguro",
      "resetEndosso",
      "endossoCadastro",
      "endossoAprovacaoCredito",
      "getReasonEndorsementRequest",
      "postValidateEndorsement",
      "validChangedObject",
      "resetEndossoModule"
    ]),

    resetarEndosso() {
      if (this.$route.params.policyUniqueId) {
        setTimeout(() => {
          this.$router.push({ name: "propostas" });
        }, 300);
        instance({
          method: "post",
          url: `api/Endorsement/CancelRequestEndorsement?PolicyUniqueId=${this.$route.params.policyUniqueId}`
        })
          .then(() => {
            console.log("sucesso");
          })
          .catch(error => {
            console.log("error: ", error);
          });
      }
    },
    listObjectDispatch(row) {
      this.listObjectArray.map(item => {
        if (item.ReasonEndorsementRequestId == row) {
          this.listObject = item.ContractualTerms;
        }
      });
    },
    async loadGetCompanyProposalTags() {
      await this.getCompanyProposalTags().then(response =>
        this.updateSeguradoraTags(
          response.map(x => {
            return { [x.Name]: x.Value };
          })
        )
      );
    },

    loadApolice() {
      this.$onpoint.loading(() => {
        return this.loadApoliceByUniqueId(this.$route.params.policyUniqueId);
      });
    },
    loadRules() {
      let object = {
        type: this.$route.params.typeId,
         id: this.$route.params.policyUniqueId
      }
      if (this.$route.params.policyUniqueId) {
        this.$onpoint.loading(() => {
          return this.loadEndossoRules(object);
        });
      }
    },

    async loadProposta() {
      return await this.$onpoint.loading(async() => {
        return await this.loadPropostaByUniqueId(
          this.$route.params.uniqueId || this.endosso.ProposalUniqueId
        )
          .then(() => {
            this.currentPage = 2;
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.Message);
          });
      });
    },

    disableProsseguirButton(tab) {
      if (
        tab == 0 ||
        (this.endosso &&
          this.endosso.EndorsementReasonId &&
          this.getValidDocuments())
      )
        return false;
      return true;
    },

    getValidDocuments() {
      let isValid = true;
      if (this.endosso.Documents)
        this.endosso.Documents.forEach(doc => {
          if (doc.IsRequired && (!doc.files || !doc.files.files.length))
            isValid = false;
        });

      return isValid;
    },

    async loadPostValidateEndorsement(props) {
      if (this.$route.name == "endosso-alteracao" && !this.changed) {
        this.$onpoint.errorModal(
          "Para prosseguir com a solicitação de endosso de alteração, você precisa realizar ao menos uma alteração nos dados originais da apólice."
        );
        return;
      }

      await this.$onpoint.loading(async() => {
        return await this.postValidateEndorsement(this.$store.state["apolice-module"])
          .then(response => {
            if (response) {
              this.listObjectArray =
                response.ValidateEndorsementResponseItems || [];
                this.listMotivos  = response.ValidateEndorsementResponseItems
              this.endorsementChangeTypeIds = response.ValidateEndorsementResponseItems
              props.nextTab();
              this.currentPage++;
            } else {
              this.$onpoint.errorModal(
                "Erro! Não podemos realizar essa opção de endosso. Entre em contato com o administrador do sistema."
              );
            }
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors[0]);
          });
      });
    },

    async loadGetReasonEndorsementRequest() {
      await this.$onpoint.loading(async () => {
        this.listMotivos = null;

        return await this.getReasonEndorsementRequest(this.$route.params)
          .then(response => {
            this.listMotivos = response;
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors[0]);
          });
      });
    },

    goToEmissao() {
      this.regrasAprovacaoAutotaticasModal = false;

      this.$router.push({
        name: "endosso-emissao",
        params: {
          uniqueId: this.endosso.ProposalUniqueId
        }
      });

      this.loadProposta();
    },

    async enviarEndossoAnalise() {
      if (
        (this.endosso.Documents || []).filter(
          doc =>
            doc.IsRequired && !(doc.files || {}).EndorsementRequestDocumentId
        ).length
      ) {
        this.$onpoint.errorModal(
          "Para prosseguir com a solicitação de endosso, insira os documentos obrigatórios!"
        );
        return;
      }

      const step = {
        name: "aprovacao_criacao",
        status: "wait",
        message: "Executando criação de endosso"
      };
      this.updateStep(step);
      this.regrasAprovacaoAutotaticasModal = true;
      let payLoad = {
        ...this.endossoPayload,
        ...this.endossoCreatePayload,
        RequestInsurancePremiumReview: this.endossoCreatePayload
          .RestoreInsurancePremium,
        RestoreInsurancePremium: this.endossoCreatePayload
          .RequestInsurancePremiumReview
      };
      
      const now = new Date()
      
      if(payLoad.EndorsementTypeId == 1){
        payLoad.EndDate = now.toJSON();
      }
      else{
        payLoad.EndDate = this.endosso.EndDate ? this.endosso.EndDate : this.apolice.EndDate
      }      

      return await this.createEndorsementPolicy(payLoad)
        .then(response => {
          if (response) {
            this.updateStep({
              ...step,
              status: "success",
              message: "Criação de endosso executado com sucesso."
            });
          }

          this.updateEndosso({
            PolicyUniqueId: this.$route.params.policyUniqueId,
            ProposalUniqueId: response.ProposalUniqueId
          });

          this.enviarApoliceCadastro();
        })
        .catch(error => {
          this.showButtonProsseguir = false;
          this.regraPodeFecharModal = false;
          this.updateStep({
            ...step,
            status: "error",
            message: "Criação de endosso executado.",
            error: error.response.data.Errors
          });
        });
    },

    async enviarApoliceCadastro() {
      const step = {
        name: "aprovacao_cadastro",
        status: "wait",
        message: "Executando regras de aprovação automática de cadastro."
      };
      this.updateStep(step);

      return await this.endossoCadastro(this.endosso.ProposalUniqueId)
        .then(response => {
          if (!response || !response.Success) {
            this.updateStep({
              ...step,
              status: "error",
              message: "Regras de aprovação automática de cadastro executada.",
              error: response.Errors
            });
            this.showButtonProsseguir = false;
            this.finishRules = true;
          } else {
            if (response.Success && (response.Alerts || []).length > 0) {
              this.updateStep({
                ...step,
                status: "success",
                message:
                  "Regras de aprovação automática de cadastro executada com sucesso.",
                alerts: response.Alerts || []
              });
            } else {
              this.updateStep({
                ...step,
                status: "success",
                message:
                  "Regras de aprovação automática de cadastro executada com sucesso."
              });
            }
            this.enviarApoliceCredito();
          }
        })
        .catch(error => {
          this.showButtonProsseguir = false;
          this.finishRules = true;
          this.updateStep({
            ...step,
            status: "error",
            message: "Regras de aprovação automática de cadastro executada.",
            error: error.response.data.Errors
          });
        });
    },

    async enviarApoliceCredito() {
      const step = {
        name: "aprovacao_credito",
        status: "wait",
        message: "Executando regras de aprovação automática de crédito."
      };
      this.updateStep(step);

      return await this.endossoAprovacaoCredito(this.endosso.ProposalUniqueId)
        .then(response => {
          if (!response || !response.Success) {
            this.updateStep({
              ...step,
              status: "error",
              message: "Regras de aprovação automática de crédito executada.",
              error: response.Errors
            });
            this.showButtonProsseguir = false;
            this.finishRules = true;
          } else {
            if (response.Success && (response.Alerts || []).length > 0) {
              this.updateStep({
                ...step,
                status: "success",
                message:
                  "Regras de aprovação automática de crédito executada com sucesso.",
                alerts: response.Alerts || []
              });
            } else {
              this.updateStep({
                ...step,
                status: "success",
                message:
                  "Regras de aprovação automática de crédito executada com sucesso."
              });
            }

            this.enviarApoliceSubscricao();
          }
        })
        .catch(error => {
          this.showButtonProsseguir = false;
          this.finishRules = true;
          this.updateStep({
            ...step,
            status: "error",
            message: "Regras de aprovação automática de crédito executada.",
            error: error.response.data.Errors
          });
        });
    },

    async enviarApoliceResseguro() {
      const step = {
        name: "aprovacao_resseguro",
        status: "wait",
        message: "Executando regras de aprovação automática de resseguro."
      };
      this.updateStep(step);

      return await this.endossoResseguro(this.endosso.ProposalUniqueId)
        .then(response => {
          if (!response || !response.Success) {
            this.updateStep({
              ...step,
              status: "error",
              message: "Regras de aprovação automática de resseguro executada.",
              error: response.Errors
            });
            this.showButtonProsseguir = false;
            this.finishRules = true;
          } else {
            if (response.Success && (response.Alerts || []).length > 0) {
              this.updateStep({
                ...step,
                status: "success",
                message:
                  "Regras de aprovação automática de resseguro executada com sucesso.",
                alerts: response.Alerts || []
              });
            } else {
              this.updateStep({
                ...step,
                status: "success",
                message:
                  "Regras de aprovação automática de resseguro executada com sucesso."
              });

              this.habilitaButtonProsseguir = true;
            }
            this.finishRules = true;
          }
        })
        .catch(error => {
          this.showButtonProsseguir = false;
          this.finishRules = true;
          this.updateStep({
            ...step,
            status: "error",
            message: "Regras de aprovação automática de resseguro executada.",
            error: error.response.data.Errors
          });
        });
    },

    async enviarApoliceSubscricao() {
      const step = {
        name: "aprovacao_subscricao",
        status: "wait",
        message: "Executando regras de aprovação automática de subscrição."
      };
      this.updateStep(step);

      this.regrasAprovacaoAutotaticasModal = true;
      return await this.endossoSubscricao(this.endossoPayload)
        .then(response => {
          if (!response || !response.Success) {
            this.updateStep({
              ...step,
              status: "error",
              message:
                "Regras de aprovação automática de subscrição executada.",
              error: response.Errors
            });
            this.showButtonProsseguir = false;
            this.finishRules = true;
          } else {
            if (response.Success && (response.Alerts || []).length > 0) {
              this.updateStep({
                ...step,
                status: "success",
                message:
                  "Regras de aprovação automática de subscrição executada com sucesso.",
                alerts: response.Alerts || []
              });
            } else {
              this.updateStep({
                ...step,
                status: "success",
                message:
                  "Regras de aprovação automática de subscrição executada com sucesso."
              });
            }
            this.enviarApoliceResseguro();
          }
        })
        .catch(error => {
          this.showButtonProsseguir = false;
          this.finishRules = true;

          this.updateStep({
            ...step,
            status: "error",
            message: "Regras de aprovação automática de subscrição executada.",
            error: error.response.data.Errors
          });
        });
    },

    cancelModalCriaEndosso() {
      this.regrasAprovacaoAutotaticasModal = false;
    },

    updateStep(data) {
      if (!this.enviarParaSeguradoraSteps) this.enviarParaSeguradoraSteps = [];

      const idx = this.enviarParaSeguradoraSteps.findIndex(item => {
        return item.name === data.name;
      });
      if (idx > -1) {
        this.enviarParaSeguradoraSteps.splice(idx, 1);
      }

      this.enviarParaSeguradoraSteps.push(data);

      if (data.status === "wait") {
        setTimeout(() => {
          this.$vs.loading({
            container: `#loading-step-${data.name}`,
            scale: 0.6
          });
        }, 300);
      }
    },

    goToRoute(route) {
      if (route == "time-line")
        this.$router.push({
          name: "timeline-proposta",
          params: {
            propostaUniqueId: this.endosso.ProposalUniqueId
          }
        });
      else this.$router.go(-1);
      this.regrasAprovacaoAutotaticasModal = false;
    }
  },
  created() {
    this.loadRules();
    this.$appInsights.trackPageView({
      name: "solicitacao-endosso-apolice",
      url: window.location.href
    });
  },
  beforeMount() {
    if (this.$route.name != "endosso-emissao") this.loadApolice();
    else this.loadProposta();
  },
  beforeDestroy() {
    if (this.$route.name != "endosso-emissao") {
      this.resetEndossoModule();
      this.resetApoliceModule();
    }
  }
};
</script>

<style lang="scss">
#endosso-wizard {
  .wizard-progress-with-circle {
    display: none;
  }
  .vue-form-wizard {
    li {
      .line {
        position: absolute;
        top: 40px;
        height: 4px;
        top: 33px !important;
        background: #c8c8c8;
        width: 100%;
        display: block;
      }

      &:first-of-type {
        .line {
          width: 50% !important;
          margin-left: 50%;
        }
      }

      &:last-of-type {
        .line {
          width: 50% !important;
        }
      }
    }
  }

  .vue-form-wizard .wizard-nav-pills {
    margin-left: 20%;
    margin-right: 20%;
  }

  .stepTitle,
  .active {
    color: #868686 !important;
    font-size: 14px;
    font-weight: bold;
  }

  .active {
    .wizard-icon-circle {
      background-color: rgba(var(--vs-warning), 1) !important;
      border-color: rgba(var(--vs-warning), 1) !important;

      .wizard-icon-container {
        background-color: rgba(var(--vs-warning), 1) !important;
      }
    }
  }

  li:not(.active) {
    .wizard-icon-circle {
      background-color: #c8c8c8 !important;
      border-color: #c8c8c8 !important;
      color: white !important;

      .wizard-icon-container {
        background-color: #c8c8c8 !important;
      }
    }
  }
}

.content-cancelamento {
  background: #f9f9f9;
  border-radius: 19px;
  width: 100%;
  padding: 40px 27px;
}

#endosso-alteracao,
.content-cancelamento {
  #endosso-loading {
    min-width: 120px;
    min-height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container-element {
    position: relative;

    .label-content {
      font-size: 0.85rem;
      color: rgba(0, 0, 0, 0.6);
      padding-left: 5px;
    }
    .label {
      transform: translateY(-95%) scale(0.9);
      transform-origin: top-left;
      position: absolute;
    }
  }
  .vs-input--label {
    transform: translateY(-95%) scale(0.9);
    transform-origin: top-left;
    position: absolute;
  }
}

.loading-step-error {
  display: none !important;
}
.loading-step-success {
  display: none !important;
}
.loading-step-warning {
  display: none !important;
}
.loading-step-wait {
  display: "block";
  width: 100%;
}
.loading-step-block {
  height: 80px;
  background-color: #fff;
}

#loading-step-endosso {
  .con-vs-loading .vs-loading {
    transform: scale(1.5) !important;
  }
}
</style>
