<template>
<section>
  <div class="breadcrumb">
    <Breadcrumb :pages="pages"/>
  </div>
  <div class="mt-5">
    <p class="title">Nova propostas</p>  
  </div>
  <div>      
    <Steps class="mt-5 mb-5 steps">      
    </Steps>
  </div>    
  <div v-if="premio && isDadosRisco" class="flex justify-center mt-16">
    <div class="premio-card">
      <div class="text">
        <i class="onpoint-currency-circle-dollar icon"></i>
        Prêmio Estimado
      </div>
      <div class="value">      
        <p class="title-value">Apólice atual</p>  
        {{premio.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL'
        })}}
      </div>
      
    </div>              
  </div> 
</section>
   
</template>

<script>
import Steps from "./components/Steps.vue";
import Breadcrumb from "@/components/onpoint-breadcrumb/";

import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  components: {  
    Steps,
    Breadcrumb,
  },
  data(){
    return{
      pages: [
        { label: "Propostas", to: "/propostas" },
        { label: "Nova proposta" },
      ],     
    }
  },
  created(){    
  },
  computed: {
    ...mapGetters("proposta-module", ["proposta"]),
    premio: {
      get() {
        return this.proposta.InsurancePremium;
      },
      set(val) {
        this.$store.commit("proposta-module/updateProposta", {
          ...this.proposta,
          InsurancePremium: val,
        });
      },
    },
    isDadosRisco(){
      return this.$route.name == "nova-proposta-dados-de-risco"
    }
  },
  methods:{
    
  }
}
</script>

<style lang="scss" scoped>
.title{
  font-size: 24px !important;
  line-height: 30px;
  font-weight: 700;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  color: rgba(75, 85, 99, 1);
}
.premio-card{
  min-width: 392px;
  min-height: 70px;
  background: #005A97;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  .icon{
    position: relative;
    top: 2px;
  }
  .text{
    padding:10px ;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
  }
  .value{
    display: flex;
    min-width: 143px;      
    background: #00365B;
    min-height: 50px;
    border-radius: 4px;
    flex-direction: column;
    .title-value{
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;      
      font-family: "Montserrat", Helvetica, Arial, sans-serif; 
      margin-bottom:5px ;
    }
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;      
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    padding: 8px 12px;
  }
}
</style>